/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="registration-input">
    <validation-provider
      v-if="!isDate"
      v-slot="{ errors }"
      :name="form.title"
      :rules="rules"
    >
      <p class="title-14 mb-2" :id="form.id"><span v-if="isRequired">*</span>{{ form.title }}</p>
      <v-text-field
        v-if="form.type === 'text' || form.type === 'number'"
        :placeholder="form.title"
        :value="form.value"
        :error-messages="errors"
        clearable
        :type="form.type"
        :prefix="form.type === 'number' && isPhone ? '+62': form.type === 'number' && isMoney ? 'Rp' : ''"
        :disabled="form.isDisabled || disabled"
        outlined
        dense
        @input="handleInput"
      ></v-text-field>
      <v-select
        v-if="form.type === 'select'"
        :value="form.value"
        :items="form.option"
        :error-messages="errors"
        :disabled="form.isDisabled || disabled"
        dense
        outlined
        @input="handleInput"
      ></v-select>
      <v-autocomplete
        v-if="form.type === 'autoComplete'"
        :value="form.value"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        hide-no-data
        hide-selected
        item-text="Description"
        :error-messages="errors"
        :disabled="form.isDisabled || disabled"
        placeholder="Temukan data"
        @input="handleInput"
      ></v-autocomplete>
      <v-textarea
        v-if="form.type === 'textArea'"
        auto-grow
        outlined
        :value="form.value"
        :placeholder="form.title"
        :error-messages="errors"
        :disabled="form.isDisabled || disabled"
        rows="5"
        @input="handleInput"
      ></v-textarea>
      <template v-if="form.type === 'checkbox'">
        <v-checkbox
          v-for="item in form.option"
          :key="item"
          v-model="form.value"
          :error-messages="errors"
          :disabled="form.isDisabled || disabled"
          :value="item"
          :label="`${item}`"
          @change="handleInput"
        ></v-checkbox>
      </template>
      <v-file-input
        v-if="form.type === 'file'"
        :value="form.value"
        outlined
        dense
        :accept="acceptFile"
        :disabled="form.isDisabled || disabled"
        :loading="isLoading"
        placeholder="Click here to select your file"
        :error="errors.length > 0"
        :error-messages="errors[0]"
        @change="handleInput"
      ></v-file-input>
      <img v-if="form.path && !form.isFormPdf" class="registration-input__img" width="600" height="400" :src="form.path" alt="img-input">
      <v-btn 
        color="primary"
        outlined
        class="mb-2"
        :min-width="120"
        v-if="form.path && form.isFormPdf"
        @click="downloadFile(form.path)"
      >
        UNDUH
      </v-btn>
    </validation-provider>
    <v-menu
      v-else-if="form.type === 'date'"
      ref="menu"
      :value="form.menu"
      :close-on-content-click="false"
      max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        <validation-provider
          v-slot="{ errors }"
          :name="form.title"
          :rules="rules"
        >
          <p class="title-14 mb-2" :id="form.id"><span v-if="isRequired">*</span>{{ form.title }}</p>
          <v-text-field
            :value="computedDateFormattedMomentjs(form.value)"
            :placeholder="form.title"
            :error-messages="errors"
            :disabled="form.isDisabled || disabled"
            prepend-icon="mdi-calendar"
            readonly
            clearable
            outlined
            dense
            v-bind="attrs"
            v-on="on"
            @click:clear="form.value = ''"
          ></v-text-field>
        </validation-provider>
      </template>
      <v-date-picker
        v-model="form.value"
        @change="form.menu = false"
      >
        <v-btn
          text
          color="primary"
          @click="$refs.menu.save(form.value)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
    <v-menu
      v-else-if="form.type === 'year'"
      ref="menu"
      :value="form.menu"
      :close-on-content-click="false"
      max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        <validation-provider
          v-slot="{ errors }"
          :name="form.title"
          :rules="rules"
        >
          <p class="title-14 mb-2" :id="form.id"><span v-if="isRequired">*</span>{{ form.title }}</p>
          <v-text-field
            :value="computedYearFormattedMomentjs(form.value)"
            :placeholder="form.title"
            :error-messages="errors"
            :disabled="form.isDisabled || disabled"
            prepend-icon="mdi-calendar"
            readonly
            clearable
            outlined
            dense
            v-bind="attrs"
            v-on="on"
            @click:clear="form.value = ''"
          ></v-text-field>
        </validation-provider>
      </template>
      <v-date-picker
        v-model="form.value"
        type="month"
        @change="form.menu = false"
      >
        <v-btn
          text
          color="primary"
          @click="$refs.menu.save(form.value)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
// Libs
import { ValidationProvider, extend } from 'vee-validate'
import { required, email, ext } from 'vee-validate/dist/rules'
import moment from 'moment'
// Utils
import request from '@/utils/request'

// Constants
import {API_UPLOAD} from '@/constants/apis'
  export default {
    data () {
      return {
        isLoading: false,
        search: '',
        descriptionLimit: 60,
        entries: []
      }
    },
    components: {
      ValidationProvider,
    },
    props: {
      form: {
        type: Object,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isRequired () {
        return this.form.validation.required ? 'required' : ''
      },
      isEmail () {
        return this.form.validation.email ? 'email' : ''
      },
      isPhone () {
        return this.form.validation.phoneNumber ? 'phoneNumber' : ''
      },
      isMaxSized () {
        return this.form.validation.maxSized ? 'maxSized' : ''
      },
      isDate () {
        return this.form.type === 'date' || this.form.type === 'year'
      },
      isPng () {
        return this.form.validation.isPng ? 'ext:png' : ''
      },
      isImg () {
        return this.form.validation.isImg ? 'ext:png,jpg,jpeg' : ''
      },
      isPdf () {
        return this.form.validation.isPdf ? 'ext:pdf' : ''
      },
      isFile () {
        return this.form.validation.isFile ? 'ext:pdf,doc,docx' : ''
      },
      isMoney () {
        return this.form.validation.isMoney ? 'isMoney' : ''
      },
      rules () {
        return `${this.isRequired}|${this.isEmail}|${this.isPhone}|${this.isMaxSized}|${this.isPng}|${this.isImg}|${this.isPdf}`
      },
      acceptFile () {
        return this.form.validation.accept ? this.form.validation.accept : ''
      },
      items () {
        return this.entries.map(entry => {
          const Description = entry.label.length > this.descriptionLimit
            ? entry.label.slice(0, this.descriptionLimit) + '...'
            : entry.label

          return Object.assign({}, entry, { Description })
        })
      },
    },
    watch: {
      search (val) {
        // Items have already been loaded
        const filter = this.items.filter(itm => itm.label.indexOf(val) !== -1)
        if (this.items.length > 0 && filter.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true
        const params = {...this.form.params}
        params.name = this.search
        request(this.form.apiUrl, params).then(res => {
          if (res.success) {
            res.list.map(itm =>{
              itm.label = `${itm.name} - ${itm.email}`
            })
            this.entries = res.list
            if (this.entries.length === 0) {
              this.form.value = ''
            }   
          }
          this.isLoading = false
        })
      },
    },
    mounted () {
      extend('required', required)
      extend('email', email)
      extend('ext', ext)
      extend('phoneNumber', {
        validate: (value) => {
          const MOBILEREG = /^(^8)(\d{3,4}-?){2}\d{2,3}$/g
          if (MOBILEREG.test(value)) {
            return true
          }
          return 'Phone Number belum sesuai'
        }
      })
      extend('maxSized', {
        validate: (value) => {
          if (value && value.size <= 2000000) {
            return true
          }
          return 'File terlalu besar, max 2Mb.'
        }
      })
    },
    methods: {
      computedDateFormattedMomentjs (date) {
        return date ? moment(new Date(date)).locale('id').format('dddd, DD MMMM YYYY') : ''
      },
      computedYearFormattedMomentjs (date) {
        return date ? moment(new Date(date)).locale('id').format('YYYY') : ''
      },
      handleInput (e) {
        this.form.value = e
        if (e === null && this.form.type === 'number') {
          this.form.value = 0
        }
        if (this.form.type === 'autoComplete') {
          this.entries.map(itm => {
            if (itm.label === this.form.value) {
              this.form.name = itm.name
              this.form.id = itm.id
            }
          })
        }
        if (this.form.type === 'file') {
          this.isLoading = true
          const formData = new FormData();
          formData.append("file", e);
          request(API_UPLOAD, formData, {content: 'multipart/form-data'}).then(res => {
            if (res.success) {
              this.form.path = res.path
            } else {
              this.form.path = ''
              this.form.value = []
            }
            this.isLoading = false
          })
        }
        this.$emit('handleInput', this.form)
      },
      downloadFile (path) {
        window.open(path, '_blank')
      },
    }
  }
</script>

<style lang="scss" scoped>
.registration-input {
  &__img {
    width: 200px;
    height: auto;
  }
  .loader {
    text-align: center;
    color: #bbbbbb;
  }
}

</style>