import {
  repeatedFields
} from '@/constants/formValidate'
import loadscreen from '@/utils/common/loadScreen'

export const validateForm = (formValidate, errors) => {
  loadscreen.show()
  let activeStep = 1
  const filteredError = []

  Object.keys(errors).map(itm => {
    if(errors[itm].length > 0) {
      filteredError.push(itm)
    }
  })
  repeatedFields.map(itm => {
    if (filteredError[0].indexOf(itm) !== -1) {
      filteredError[0] = itm
    }
  })
  const step = formValidate.filter(itm => itm.title === filteredError[0])
  if (step[0]) {
    activeStep = step[0] && step[0].step || 1
    document.querySelector(`#${step[0].id}`).scrollIntoView()
  } else {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 500)
  }
  loadscreen.hide()
  return activeStep
}