<template>
  <div>
    <v-alert
      dismissible
      type="error"
      v-model="isShowAlert"
    >{{ alertMsg }}</v-alert>
    <validation-observer ref="form">
      <v-form id="form-recommendation-a" @submit.prevent="submitForm">
        <div>
          <v-row no-gutters class="general-form">
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              class="general-form__col"
              v-for="(item, index) in getStudentData"
              :key="index"
            >
              <template v-for="form in item.list">
                <registration-input 
                  :key="form.title" 
                  :form="form"
                  @handleInput="handleInput"
                />
              </template>
            </v-col>
          </v-row>
        </div>
        <v-stepper
          v-model="activeStep"
          elevation="0"
          vertical
          class="stepper-form-recom-a"
        >
          <v-stepper-step
            :step="1"
            @click="handleStep(1)"
          >
            <p class="stepper-form-recom-a-title">Akademik dan Umum</p>
          </v-stepper-step>

          <v-stepper-content :step="1">
            <template
              v-for="form in getAcademicData"
            >
              <registration-input 
                :key="form.title" 
                :form="form"
                @handleInput="handleInput"
              />
            </template>
            <v-divider v-if="getAcademicDataList.length"></v-divider>
            <p v-if="getAcademicDataList.length" class="stepper-form-recom-a-title my-4">Mohon berikan perkiraan nilai calon mahasiswa untuk studi teologi </p>
            <template v-for="(itm, index) in getAcademicDataList">
              <p class="title-14 mb-2" :key="`adh${index}`">{{ itm.list[0].head }}</p>
              <v-row no-gutters :key="`ad${index}`" class="general-form">
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  class="general-form__col"
                  v-for="(item, idx) in separateColoum(itm.list)"
                  :key="`od${idx}`"
                >
                  <template
                    v-for="form in item.list"
                  >
                    <registration-input 
                      :key="form.title" 
                      :form="form"
                      @handleInput="handleInput"
                    />
                  </template>
                </v-col>
              </v-row>
              <v-divider v-if="index !== 1" :key="`odDiv${index}`" class="mb-4" ></v-divider>
            </template>
            <v-divider v-if="getAcademicDataList.length" ></v-divider>
            <template
              v-for="form in getGeneralData"
            >
              <registration-input 
                :key="form.title" 
                :form="form"
                @handleInput="handleInput"
              />
            </template>
          </v-stepper-content>
          <v-stepper-step
            :step="2"
            @click="handleStep(2)"
          >
            <p class="stepper-form-recom-a-title">Kepribadian dan Evaluasi Karakter</p>
          </v-stepper-step>

          <v-stepper-content :step="2">
            <p class="body-14 mb-2">Mohon berikan penilaian terhadap calon mahasiswa berdasarkan karakteristik yang terdaftar di bawah ini, pilihlah karakter yang paling melambangkan evaluasi Anda.</p>
            <template v-for="(itm, index) in getCharEvaluation">
              <v-row v-if="index === 0" no-gutters :key="`charData${index}`" class="general-form">
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  class="general-form__col"
                  v-for="(item, idx) in separateColoum(itm.list)"
                  :key="`ce${idx}`"
                >
                  <template
                    v-for="form in item.list"
                  >
                    <registration-input 
                      :key="form.title" 
                      :form="form"
                      @handleInput="handleInput"
                    />
                  </template>
                </v-col>
              </v-row>
              <template
                v-else
                v-for="form in itm.list"
              >
                <registration-input 
                  :key="form.title" 
                  :form="form"
                  @handleInput="handleInput"
                />
              </template>
              <v-divider class="mb-4" v-if="index !== 2" :key="`ceDiv${index}`" ></v-divider>
            </template>
          </v-stepper-content>
          <v-stepper-step
            :step="3"
            @click="handleStep(3)"
          >
            <p class="stepper-form-recom-a-title">Rekomendasi</p>
          </v-stepper-step>
          <v-stepper-content :step="3">
            <template
              class="general-form"
              v-for="form in getRecommendation"
            >
              <registration-input 
                :key="form.title" 
                :form="form"
                @handleInput="handleInput"
              />
            </template>
            <template v-if="prodi === 'sert'">
              <v-divider ></v-divider>
              <p class="body-14 my-4">Bila memungkinkan, mohon berikan data mengenai orang lain yang dapat memberikan penilaian terhadap calon mahasiswa:</p>
              <v-row  no-gutters v-for="(itm, index) in getOtherRecommendation" :key="`recom${index}`" class="general-form">
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  class="general-form__col"
                  v-for="(item, idx) in separateColoum(itm.list)"
                  :key="`sk${idx}`"
                >
                  <template
                    v-for="form in item.list"
                  >
                    <registration-input 
                      :key="form.title" 
                      :form="form"
                      @handleInput="handleInput"
                    />
                  </template>
                </v-col>
                <template v-if="itm.list[0].isMultiData">
                  <v-btn outlined :min-width="120" class="mr-2" :key="`sDataBtn1${index}`" @click="handleMultiForm('remove', itm.list[0])" :disabled="itm.list[0].isDisabledRemove">
                    HAPUS
                  </v-btn>
                  <v-btn color="primary" :min-width="120" :key="`sDataBtn2${index}`" @click="handleMultiForm('add', itm.list[0])">
                    TAMBAH
                  </v-btn>
                </template>
              </v-row>
              <v-divider class="mt-4"></v-divider>
            </template>
            <p class="body-14 my-4">Diisi oleh</p>
            <v-row no-gutters class="general-form">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="general-form__col"
                v-for="(item, index) in separateColoum(getFillerData)"
                :key="index"
              >
                <template v-for="form in item.list">
                  <registration-input 
                    :key="form.title" 
                    :form="form"
                    @handleInput="handleInput"
                  />
                </template>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step
            :step="4"
            @click="handleStep(4)"
          >
            <p class="stepper-form-recom-a-title">Persetujuan</p>
          </v-stepper-step>

          <v-stepper-content :step="4">
             <v-row no-gutters class="general-form">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="general-form__col"
                v-for="(item, idx) in separateColoum(getAgreement)"
                :key="`ag${idx}`"
              >
                <template
                  v-for="form in item.list"
                >
                  <registration-input 
                    :key="form.title" 
                    :form="form"
                    @handleInput="handleInput"
                  />
                </template>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper>
        <div>
          <p class="stepper-form-recom-a-title">Kebijakan Konfidensial</p>
          <p class="stepper-form-recom-a-body">Seluruh informasi yang diberikan oleh perekomendasi bersifat rahasia dan hanya akan digunakan untuk tujuan aplikasi admisi, pembinaan, dan arsip STT Amanat Agung.</p>
        </div>
        <div class="d-flex justify-end">
          <v-btn 
            color="primary"
            :min-width="160"
            type="submit"
            form="form-recommendation-a"
            large
          >
            KIRIM
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
    <dialog-info ref="dialogInfoInvalid" :info="infoInvalid"/>
    <dialog-confirm ref="dialogConfirmSubmitForm" :info="info" :action="handleOnSubmit"/>
  </div>
</template>

<script>
// Libs
import { ValidationObserver, extend } from 'vee-validate'
import { get } from 'dot-prop'

// Utils
import loadscreen from '@/utils/common/loadScreen'
import request from '@/utils/request'
import { checkBCheckbox } from '@/utils/common/checkHeardStta'
import { validateForm } from '@/utils/common/validateForm'

// Constants
import {
  API_SUBMIT_RECOMMENDATION
} from '@/constants/apis'
import {
  WEB_HOME
} from '@/constants/pages'
import {
  formValidateRecommendation
} from '@/constants/formValidate'
import { required } from 'vee-validate/dist/rules'
import RegistrationInput from '@/components/common/registrationInput'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'

  export default {
    data () {
      return {
        activeStep: 1,
        concentration: 'Penggembalaan',
        isShowDivorced: false,
        agreementCheckbox: false,
        info: {
          desc: 'Anda yakin data yang anda masukan sudah benar?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.submit.form',
          btn: 'dialog.success.btn'
        },
        infoInvalid: {
          title: 'dialog.invalid',
          desc: 'dialog.invalid.form',
          btn: 'dialog.success.btn'
        },
        isShowAlert: false,
        alertMsg: '',
        prodi: 'sth'
      }
    },
    components: {
      ValidationObserver,
      RegistrationInput,
      DialogConfirm,
      DialogInfo
    },
    props: {
      tokenId: {
        type: String,
        default: ''
      },
      studentName: {
        type: String,
        default: ''
      }
    },
    computed: {
      getStudentData () {
        const studentData = get(this.$store.state, 'web.formRecommend.student_data', [])
        if (this.studentName) {
          studentData[0].value = this.studentName
        }
        return [{
          list: studentData
        }]
      },
      getAcademicData () {
        const personalData = get(this.$store.state, 'web.formRecommend.academic_and_general', [])
        return personalData.slice(0, 1)
      },
      getAcademicDataList () {
        const personalData = get(this.$store.state, 'web.formRecommend.academic_and_general', [])
        if (['mth', 'sth', 'mthu', 'mmin'].indexOf(this.prodi) === -1) {
          return [this.getData(personalData)[1], this.getData(personalData)[2]]
        }
        return []
      },
      getGeneralData () {
        const personalData = get(this.$store.state, 'web.formRecommend.academic_and_general', [])
        const idx = ['mth', 'sth', 'mthu', 'mmin'].indexOf(this.prodi) === -1 ? 3 : 1
        return this.getData(personalData)[idx].list
      },
      getCharEvaluation () {
        const evaluationData = get(this.$store.state, 'web.formRecommend.character_evaluation', [])
        const idx = ['mth', 'sth', 'mthu', 'mmin'].indexOf(this.prodi) === -1 ? 3 : 2
        return this.getData(evaluationData).slice(0, idx)
      },
      getRecommendation () {
        const recommendationData = get(this.$store.state, 'web.formRecommend.recommendation', [])
        return this.getData(recommendationData)[0].list
      },
      getOtherRecommendation () {
        const recommendation_other_person = get(this.$store.state, 'web.formRecommend.recommendation_other_person', [])
        return this.getData(recommendation_other_person)
      },
      getFillerData () {
        const filler_data = get(this.$store.state, 'web.formRecommend.filler_data', [])
        return filler_data
      },
      getAgreement () {
        const agreementData = get(this.$store.state, 'web.formRecommend.agreement_data', [])
        return agreementData
      },
    },
    created () {
      const type = get(this.$route.query, 'type', 'A') 
      this.prodi = get(this.$route.query, 'prodi', '')
      this.$store.dispatch('web/getFormRecommend', {type, prodi: this.prodi})
    },
    mounted () {
      extend('required', required)
    },
    methods: {
      getData (item) {
        const data = []
        item.map(itm => {
          if (!data[itm.group-1]) {
            const list = []
            list.push(itm)
            data.push({list})
          } else {
            data[itm.group-1].list.push(itm)
          }
        })
        return data
      },
      separateColoum (list = []) {
        const len = list.length
        const median = Math.ceil(list.length/2)
        return [{
          list: list.slice(0, median)
        }, {
          list: list.slice(median, len)
        }]
      },
      handleStep (id) {
        this.activeStep = id
      },
      handleMultiForm (type, itm) {
        if (type === 'add') {
          this.$store.dispatch('web/addFormRecom', itm)
        } else {
          this.$store.dispatch('web/removeFormRecom', itm)
        }
      },
      submitForm () {
        this.$refs.form.validate().then(success => {
          if (!success) {
            const errors = this.$refs.form.errors
            this.activeStep = validateForm(formValidateRecommendation, errors)
            this.$refs.dialogInfoInvalid && this.$refs.dialogInfoInvalid.show()
          } else {
            // success
            this.$refs.dialogConfirmSubmitForm && this.$refs.dialogConfirmSubmitForm.show()
          }
        })
      },
      handleOnSubmit () {
        loadscreen.show()
        const formRecommend = get(this.$store.state, 'web.formRecommend', {})
        let params = {
          student_data: {},
          academic_and_general: {},
          character_evaluation: {},
          recommendation: {},
          recommendation_other_person: {
            list: []
          },
          filler_data: {},
          agreement_data: {},
        }
        Object.keys(formRecommend).map(item => {
          if (typeof formRecommend[item] !== 'string') {
            formRecommend[item].map(itm => {
              let value = itm.value
              if (itm.type === 'date' || itm.type === 'year') {
                value = Date.parse(new Date(itm.value))/1000 ? Date.parse(new Date(itm.value))/1000 : 0
              }
              if (itm.type === 'select') {
                value = itm.value === 'Ya' ? true : itm.value === 'Tidak' ? false : itm.value
              }
              if (itm.type === 'file') {
                value = itm.path
              }
              if (itm.subfields) {
                if (params[itm.parent][itm.fields].length === 0) {
                  params[itm.parent][itm.fields] = []
                  params[itm.parent][itm.fields].push({
                    [itm.subfields]: value,
                    attr: itm.attr
                  })
                } else {
                  const idx = params[itm.parent][itm.fields].findIndex(obj => obj.attr === itm.attr)
                  if (idx !== -1) {
                    params[itm.parent][itm.fields].map(obj => {
                      if (obj.attr === itm.attr) {
                        obj[itm.subfields] = value
                      }
                    })
                  }
                  else {
                    params[itm.parent][itm.fields].push({
                      [itm.subfields]: value,
                      attr: itm.attr
                    })
                  }
                }
              } else {
                params[itm.parent][itm.fields] = value
              }
            })
          }
        })
        params.character_evaluation.b_checkbox = checkBCheckbox(params.character_evaluation.b_checkbox)
        const objParam = {
          id_token: this.tokenId,
          token: get(this.$route.query, 'token', ''),
          entry_form_id: get(this.$route.query, 'formId', ''),
          type: get(this.$route.query, 'type', ''),
          recommendation_other_person: [...params.recommendation_other_person.list],
          ...params.student_data,
          ...params.academic_and_general,
          ...params.character_evaluation,
          ...params.recommendation,
          ...params.filler_data,
          ...params.agreement_data,
          ...params.character_evaluation.b_checkbox
        }
        objParam.b_checkbox = null
        loadscreen.hide()
        request(API_SUBMIT_RECOMMENDATION, objParam).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
          loadscreen.hide()
        })
      },
      handleBack () {
        this.$router.replace({
          name: WEB_HOME
        }).catch(() => {})
      },
      handleInput (form) {
        // console.log(form)
      }
    }
  }
</script>

<style lang="scss" scoped>
.stepper-form-recom-a {
  &-title {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    color: black;
  }
  &-body {
    font-size: 14px;
    margin: 0;
    color: black;
  }
}

</style>