<template>
<div class="recommendation">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2>{{title}}</h2>
          <p class="body-14 mt-4 mb-4">{{info}}</p>
          <form-recommendation :tokenId="tokenId" :studentName="studentName"/>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import formRecommendation from '@/apps/web/components/forms/formRecommendation'
import {get} from 'dot-prop'
import request, {fetchOauth} from '@/utils/request'
import store from '@/store'
import {
  WEB_HOME
} from '@/constants/pages'
import {
  API_VALID_RECOMMENDATION
} from '@/constants/apis'
export default {
  name: 'recommendation',
  components: { formRecommendation },
  data () {
    return {
      tokenId: '',
      studentName: ''
    }
  },
  head: {
    link: [
      { rel: 'canonical', href: 'https://admisi.sttaa.ac.id/recommendation', id: 'canonical' },
    ]
  },
  computed: {
    isRecommendA () {
      return get(this.$route.query, 'type', 'A') === 'A'
    },
    isRecommendB () {
      return get(this.$route.query, 'type', 'A') === 'B'
    },
    title () {
      return this.isRecommendA ? 'FORMULIR REKOMENDASI A' : this.isRecommendB ? 'FORMULIR REKOMENDASI B' : 'FORMULIR REKOMENDASI C'
    },
    info () {
      return this.isRecommendA
        ? 'Diisi dan dikirim langsung oleh Hamba Tuhan yang melayani di Gereja atau Lembaga Kristen yang sama dengan calon mahasiswa, dan telah mengenal calon mahasiswa minimal 2 tahun.' 
        : this.isRecommendB 
        ? 'Diisi dan dikirim langsung oleh teman atau sahabat (di luar keluarga atau pacar) yang telah mengenal calon mahasiswa minimal 2 tahun.' 
        : 'Diisi dan dikirim langsung oleh dosen institusi sebelumnya yang mengenal calon mahasiswa minimal 2 tahun.'
    }
  },
  beforeRouteEnter (to, from, next) {
    const type = get(to.query, 'type', '')
    const prodi = get(to.query, 'prodi', '')
    const isValidTypeA = type === 'A' && ['sth', 'mmin', 'sert', 'mth', 'mdiv', 'mthu'].indexOf(prodi) !== -1
    const isValidTypeB = type === 'B' && ['sth', 'mdiv', 'mth', 'mthu'].indexOf(prodi) !== -1
    const isValidTypeC = type === 'C' && ['mth'].indexOf(prodi) !== -1
    localStorage.removeItem('feConfig')
    fetchOauth().then(res => {
      if (res.success) {
        const feConfig = {
          token: res.access_token || '',
          expiredAt: res.expires_in || ''
        }
        localStorage.setItem('feConfig', JSON.stringify(feConfig))
        const params = {
          entry_form_id: get(to.query, 'formId', ''),
          type: get(to.query, 'type', ''),
          token: get(to.query, 'token', '')
        }
        request(API_VALID_RECOMMENDATION, params).then(res => {
          if (res.success && (isValidTypeA || isValidTypeB || isValidTypeC)) {
            next(vm => {
              vm.tokenId = res.id_token || ''
              vm.studentName = res.student_name || ''
            })
          } else {
            store.dispatch('web/setErrorRecommendation', true)
            next({
              name: WEB_HOME,
            })
          }
        })
      } else {
        next({
          name: WEB_HOME
        })
      }
    })
  },
}
</script>

<style lang="scss" scoped>

</style>