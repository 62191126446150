<template>
  <v-dialog
    v-model="dialog"
    max-width="550"
  >
    <v-card>
      <v-card-title class="text-subtitle-1 title-break">
        {{ info.desc }}
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="hide()"
        >
          BATAL
        </v-btn>
        <v-btn
          color="primary"
          @click="handleDialog()"
        >
          LANJUT
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DialogConfirm',
    props: {
      info: {
        required: true,
        type: Object
      },
      action: {
        type: Function,
        default: () => {}
      }
    },
    data () {
      return {
        dialog: false,
      }
    },
    methods: {
      show () {
        this.dialog = true
      },
      hide () {
        this.dialog = false
      },
      handleDialog() {
        this.hide()
        this.action()
      }
    }
  }
</script>
<style lang="scss" scoped>
.title-break {
  word-break: break-word;
}
</style>
