export const repeatedFields = [
  'Nama Anak',
  'Tempat Lahir Anak', 
  'Tanggal Lahir Anak', 
  'Pendidikan Terakhir Anak', 
  'Perguruan Tinggi', 
  'Program Study', 
  'Tahun Kelulusan', 
  'IPK'
]

export const formValidateMth = [{
  id: 'fullnamePersonalData',
  step: 1,
  title: 'Nama Lengkap (Sesuai Akta Lahir)',
}, {
  id: 'genderPersonalData',
  step: 1,
  title: 'Jenis Kelamin',
}, {
  id: 'birthPlacePersonalData',
  step: 1,
  title: 'Tempat Lahir',
},{
  id: 'birthDatePersonalData',
  step: 1,
  title: 'Tanggal Lahir',
}, {
  id: 'nationalityPersonalData',
  step: 1,
  title: 'Warganegara',
}, {
  id: 'idPassportPersonalData',
  step: 1,
  title: 'NIK (WNI)',
}, {
  id: 'addressPersonalData',
  step: 1,
  title: 'Alamat Lengkap',
}, {
  id: 'cityPersonalData',
  step: 1,
  title: 'Kota/Provinsi',
}, {
  id: 'phonePersonalData',
  step: 1,
  title: 'Nomor HP Pribadi',
}, {
  id: 'emailPersonalData',
  step: 1,
  title: 'Alamat e-mail',
}, {
  id: 'isDivorceMarriageData',
  step: 3,
  title: 'Pernahkah bercerai?',
}, {
  id: 'mentalIllnessDesc',
  step: 4,
  title: 'Bila “ya”, mohon jelaskan secara ringkas. Bila Tidak, silakan isi dengan "-"',
}, {
  id: 'mentalIllnessFam',
  step: 4,
  title: 'Apakah ada anggota keluarga Anda yang mempunyai riwayat/masalah kejiwaan?',
}, {
  id: 'IllnessDesc',
  step: 4,
  title: 'Apakah Anda pernah/sedang menderita penyakit tertentu, seperti jantung, paru-paru, ginjal, TBC, kanker, HIV-AIDS, hepatitis, epilepsi, Autoimun, Lambung/Mag, Asma? Berikan penjelasan mengenai kondisi Anda pada saat ini berkaitan dengan penyakit yang pernah/sedang diderita.',
}, {
  id: 'jobHistoryPlaceKe-1',
  step: 5,
  title: 'Tempat Kerja Ke-1'
}, {
  id: 'jobHistoryPositionKe-1',
  step: 5,
  title: 'Jabatan/ Posisi Ke-1'
}, {
  id: 'churchMember',
  step: 6,
  title: 'Anggota Gereja'
}, {
  id: 'churchAddressMember',
  step: 6,
  title: 'Alamat Gereja'
}, {
  id: 'churchCityMember',
  step: 6,
  title: 'Kota/ Provinsi Gereja'
}, {
  id: 'churchPastorMember',
  step: 6,
  title: 'Gembala/ Pendeta Senior'
}, {
  id: 'churchActiveMember',
  step: 6,
  title: 'Sekarang Aktif di Gereja'
}, {
  id: 'personalBadHabit',
  step: 8,
  title: 'Sebutkan karakter atau kebiasaan buruk yang masih menjadi pergumulan Anda.'
}, {
  id: 'personalOvercomeEffort',
  step: 8,
  title: 'Bagaimana usaha Anda untuk menyelesaikan atau mengatasinya?'
}, {
  id: 'heardFromWhere',
  step: 9,
  title: 'Darimana Anda mengetahui tentang STT Amanat Agung?'
}, {
  id: 'agreementDate',
  step: 10,
  title: 'Tanggal Menyatakan Persetujuan'
}, {
  id: 'agreementPict',
  step: 10,
  title: 'Tanda Tangan Anda (file PNG)'
}, {
  id: 'parentAgreementPict',
  step: 10,
  title: 'Tanda Tangan Orang Tua/ Wali (file PNG)'
}]
export const formValidateMmin = [{
  id: 'fullnamePersonalData',
  step: 1,
  title: 'Nama Lengkap (Sesuai Akta Lahir)',
}, {
  id: 'genderPersonalData',
  step: 1,
  title: 'Jenis Kelamin',
}, {
  id: 'birthPlacePersonalData',
  step: 1,
  title: 'Tempat Lahir',
},{
  id: 'birthDatePersonalData',
  step: 1,
  title: 'Tanggal Lahir',
}, {
  id: 'nationalityPersonalData',
  step: 1,
  title: 'Warganegara',
}, {
  id: 'idPassportPersonalData',
  step: 1,
  title: 'NIK (WNI)',
}, {
  id: 'addressPersonalData',
  step: 1,
  title: 'Alamat Lengkap',
}, {
  id: 'cityPersonalData',
  step: 1,
  title: 'Kota/Provinsi',
}, {
  id: 'phonePersonalData',
  step: 1,
  title: 'Nomor HP Pribadi',
}, {
  id: 'emailPersonalData',
  step: 1,
  title: 'Alamat e-mail',
}, {
  id: 'isDivorceMarriageData',
  step: 3,
  title: 'Pernahkah bercerai?',
}, {
  id: 'mentalIllnessDesc',
  step: 4,
  title: 'Bila “ya”, mohon jelaskan secara ringkas. Bila Tidak, silakan isi dengan "-"',
}, {
  id: 'mentalIllnessFam',
  step: 4,
  title: 'Apakah ada anggota keluarga Anda yang mempunyai riwayat/masalah kejiwaan?',
}, {
  id: 'IllnessDesc',
  step: 4,
  title: 'Apakah Anda pernah/sedang menderita penyakit tertentu, seperti jantung, paru-paru, ginjal, TBC, kanker, HIV-AIDS, hepatitis, epilepsi, Autoimun, Lambung/Mag, Asma? Berikan penjelasan mengenai kondisi Anda pada saat ini berkaitan dengan penyakit yang pernah/sedang diderita.',
}, {
  id: 'collegeUnivHistory',
  step: 5,
  title: 'Perguruan Tinggi ke-1'
}, {
  id: 'majorUnivHistory',
  step: 5,
  title: 'Program Study Ke-1'
}, {
  id: 'gradYearUnivHistory',
  step: 5,
  title: 'Tahun Kelulusan Ke-1'
}, {
  id: 'ipkUnivHistory',
  step: 5,
  title: 'IPK (0.0 - 4.0) Ke-1'
}, {
  id: 'jobHistoryPlaceKe-1',
  step: 6,
  title: 'Tempat Kerja Ke-1'
}, {
  id: 'jobHistoryPlaceKe-1',
  step: 6,
  title: 'Tempat Kerja Ke-1'
}, {
  id: 'jobHistoryPositionKe-1',
  step: 6,
  title: 'Jabatan/ Posisi Ke-1'
}, {
  id: 'churchMember',
  step: 7,
  title: 'Anggota Gereja'
}, {
  id: 'churchAddressMember',
  step: 7,
  title: 'Alamat Gereja'
}, {
  id: 'churchCityMember',
  step: 7,
  title: 'Kota/ Provinsi Gereja'
}, {
  id: 'churchPastorMember',
  step: 7,
  title: 'Gembala/ Pendeta Senior'
}, {
  id: 'churchActiveMember',
  step: 7,
  title: 'Sekarang Aktif di Gereja'
}, {
  id: 'personalBadHabit',
  step: 9,
  title: 'Sebutkan karakter atau kebiasaan buruk yang masih menjadi pergumulan Anda.'
}, {
  id: 'personalOvercomeEffort',
  step: 9,
  title: 'Bagaimana usaha Anda untuk menyelesaikan atau mengatasinya?'
}, {
  id: 'heardFromWhere',
  step: 10,
  title: 'Darimana Anda mengetahui tentang STT Amanat Agung?'
}, {
  id: 'agreementDate',
  step: 11,
  title: 'Tanggal Menyatakan Persetujuan'
}, {
  id: 'agreementPict',
  step: 11,
  title: 'Tanda Tangan Anda (file PNG)'
}]

export const formValidateSthMdiv = [{
  id: 'fullnamePersonalData',
  step: 1,
  title: 'Nama Lengkap (Sesuai Akta Lahir)',
}, {
  id: 'genderPersonalData',
  step: 1,
  title: 'Jenis Kelamin',
}, {
  id: 'birthPlacePersonalData',
  step: 1,
  title: 'Tempat Lahir',
},{
  id: 'birthDatePersonalData',
  step: 1,
  title: 'Tanggal Lahir',
}, {
  id: 'nationalityPersonalData',
  step: 1,
  title: 'Warganegara',
}, {
  id: 'idPassportPersonalData',
  step: 1,
  title: 'NIK (WNI)',
}, {
  id: 'addressPersonalData',
  step: 1,
  title: 'Alamat Lengkap',
}, {
  id: 'cityPersonalData',
  step: 1,
  title: 'Kota/Provinsi',
}, {
  id: 'phonePersonalData',
  step: 1,
  title: 'Nomor HP Pribadi',
}, {
  id: 'emailPersonalData',
  step: 1,
  title: 'Alamat e-mail',
}, {
  id: 'role1FamilyData',
  step: 2,
  title: 'Ayah/ Wali',
}, {
  id: 'name1FamilyData',
  step: 2,
  title: 'Nama Ayah/ Wali',
}, {
  id: 'phone1FamilyData',
  step: 2,
  title: 'Nomor Telepon/ HP Ayah/ Wali',
}, {
  id: 'job1FamilyData',
  step: 2,
  title: 'Pekerjaan Ayah/ Wali',
}, {
  id: 'role2FamilyData',
  step: 2,
  title: 'Ibu/ Wali',
}, {
  id: 'name2FamilyData',
  step: 2,
  title: 'Nama Ibu/ Wali',
}, {
  id: 'phone2FamilyData',
  step: 2,
  title: 'Nomor Telepon/ HP Ibu/ Wali',
}, {
  id: 'job2FamilyData',
  step: 2,
  title: 'Pekerjaan Ibu/ Wali',
}, {
  id: 'isDivorceMarriageData',
  step: 3,
  title: 'Pernahkah bercerai?',
}, {
  id: 'mentalIllnessDesc',
  step: 4,
  title: 'Bila “ya”, mohon jelaskan secara ringkas. Bila Tidak, silakan isi dengan "-"',
}, {
  id: 'mentalIllnessFam',
  step: 4,
  title: 'Apakah ada anggota keluarga Anda yang mempunyai riwayat/masalah kejiwaan?',
}, {
  id: 'IllnessDesc',
  step: 4,
  title: 'Apakah Anda pernah/sedang menderita penyakit tertentu, seperti jantung, paru-paru, ginjal, TBC, kanker, HIV-AIDS, hepatitis, epilepsi, Autoimun, Lambung/Mag, Asma?',
}, {
  id: 'ExistIllnessDesc',
  step: 4,
  title: 'Berikan penjelasan mengenai kondisi Anda pada saat ini berkaitan dengan penyakit yang pernah/sedang diderita!',
}, {
  id: 'jobHistoryPlaceKe-1',
  step: 5,
  title: 'Tempat Kerja Ke-1'
}, {
  id: 'jobHistoryPositionKe-1',
  step: 5,
  title: 'Jabatan/ Posisi Ke-1'
}, {
  id: 'churchMember',
  step: 7,
  title: 'Anggota Gereja'
}, {
  id: 'churchAddressMember',
  step: 7,
  title: 'Alamat Gereja'
}, {
  id: 'churchCityMember',
  step: 7,
  title: 'Kota/ Provinsi Gereja'
}, {
  id: 'churchPastorMember',
  step: 7,
  title: 'Gembala/ Pendeta Senior'
}, {
  id: 'churchActiveMember',
  step: 7,
  title: 'Sekarang Aktif di Gereja'
}, {
  id: 'fullBibleReadAmount',
  step: 9,
  title: 'Sudah berapa kali Anda membaca Alkitab secara utuh dari awalsampai akhir?'
}, {
  id: 'spiritualFriendName',
  step: 9,
  title: 'Jika Ada, sebutkan. (isi dengan - bila Tidak)'
}, {
  id: 'personalBadHabit',
  step: 10,
  title: 'Sebutkan karakter atau kebiasaan buruk yang masih menjadi pergumulan Anda.'
}, {
  id: 'personalOvercomeEffort',
  step: 10,
  title: 'Bagaimana usaha Anda untuk menyelesaikan atau mengatasinya?'
}, {
  id: 'heardFromWhere',
  step: 11,
  title: 'Darimana Anda mengetahui tentang STT Amanat Agung?'
}, {
  id: 'agreementPict',
  step: 12,
  title: 'Tanda Tangan Anda (file PNG)'
}, {
  id: 'parentAgreementName',
  step: 12,
  title: 'Nama Orang Tua/ Wali'
}, {
  id: 'parentAgreementPict',
  step: 12,
  title: 'Tanda Tangan Orang Tua/ Wali (file PNG)'
}]

export const formValidateSert = [{
  id: 'fullnamePersonalData',
  step: 1,
  title: 'Nama Lengkap (Sesuai Akta Lahir)',
}, {
  id: 'genderPersonalData',
  step: 1,
  title: 'Jenis Kelamin',
}, {
  id: 'birthPlacePersonalData',
  step: 1,
  title: 'Tempat Lahir',
},{
  id: 'birthDatePersonalData',
  step: 1,
  title: 'Tanggal Lahir',
}, {
  id: 'nationalityPersonalData',
  step: 1,
  title: 'Warganegara',
}, {
  id: 'idPassportPersonalData',
  step: 1,
  title: 'NIK (WNI)',
}, {
  id: 'naPassportPersonalData',
  step: 1,
  title: 'Nomor Paspor (WNA, berikan tanda - atau 0 apabila Anda WNI)',
}, {
  id: 'addressPersonalData',
  step: 1,
  title: 'Alamat Lengkap',
}, {
  id: 'cityPersonalData',
  step: 1,
  title: 'Kota/Provinsi',
}, {
  id: 'postalCodePersonalData',
  step: 1,
  title: 'Kota/Kode Pos',
}, {
  id: 'homePhonePersonalData',
  step: 1,
  title: 'Nomor Telepon Rumah',
}, {
  id: 'phonePersonalData',
  step: 1,
  title: 'Nomor HP Pribadi',
}, {
  id: 'emailPersonalData',
  step: 1,
  title: 'Alamat e-mail',
}, {
  id: 'nonResideNamePersonalData',
  step: 1,
  title: 'Nama',
}, {
  id: 'nonResidePhonePersonalData',
  step: 1,
  title: 'Nomor HP',
}, {
  id: 'role1FamilyData',
  step: 2,
  title: 'Ayah/ Wali',
}, {
  id: 'name1FamilyData',
  step: 2,
  title: 'Nama Ayah/ Wali',
}, {
  id: 'phone1FamilyData',
  step: 2,
  title: 'Nomor Telepon/ HP Ayah/ Wali',
}, {
  id: 'job1FamilyData',
  step: 2,
  title: 'Pekerjaan Ayah/ Wali',
}, {
  id: 'role2FamilyData',
  step: 2,
  title: 'Ibu/ Wali',
}, {
  id: 'name2FamilyData',
  step: 2,
  title: 'Nama Ibu/ Wali',
}, {
  id: 'phone2FamilyData',
  step: 2,
  title: 'Nomor Telepon/ HP Ibu/ Wali',
}, {
  id: 'job2FamilyData',
  step: 2,
  title: 'Pekerjaan Ibu/ Wali',
}, {
  id: 'dateMarriageData',
  step: 3,
  title: 'Data Pernikahan',
}, {
  id: 'nameMarriageData',
  step: 3,
  title: 'Nama Suami/ Istri',
}, {
  id: 'lastEduMarriageData',
  step: 3,
  title: 'Pendidikan Terakhir Suami/ Istri',
}, {
  id: 'isHaveChildMarriageData',
  step: 3,
  title: 'Apakah Anda sudah memiliki anak?',
}, {
  id: 'isDivorceMarriageData',
  step: 3,
  title: 'Pernahkah bercerai?',
}, {
  id: 'nameChildMarriageData',
  step: 3,
  title: 'Nama Anak',
}, {
  id: 'lastEduChildMarriageData',
  step: 3,
  title: 'Pendidikan Terakhir Anak',
}, {
  id: 'schoolNameSD',
  step: 4,
  title: 'Nama Sekolah SD',
}, {
  id: 'schoolCitySD',
  step: 4,
  title: 'Kota SD',
}, {
  id: 'schoolGradYearSD',
  step: 4,
  title: 'Tahun Kelulusan SD',
}, {
  id: 'schoolNameSMP',
  step: 4,
  title: 'Nama Sekolah SMP',
}, {
  id: 'schoolCitySMP',
  step: 4,
  title: 'Kota SMP',
}, {
  id: 'schoolGradYearSMP',
  step: 4,
  title: 'Tahun Kelulusan SMP',
}, {
  id: 'schoolNameSMA',
  step: 4,
  title: 'Nama Sekolah SMA',
}, {
  id: 'schoolCitySMA',
  step: 4,
  title: 'Kota SMA',
}, {
  id: 'schoolGradYearSMA',
  step: 4,
  title: 'Tahun Kelulusan SMA',
}, {
  id: 'jobHistoryPlaceKe-1',
  step: 5,
  title: 'Tempat Kerja Ke-1'
}, {
  id: 'jobHistoryPositionKe-1',
  step: 5,
  title: 'Jabatan/ Posisi Ke-1'
}, {
  id: 'churchMember',
  step: 7,
  title: 'Anggota Gereja'
}, {
  id: 'churchAddressMember',
  step: 7,
  title: 'Alamat Gereja'
}, {
  id: 'churchCityMember',
  step: 7,
  title: 'Kota/ Provinsi Gereja'
}, {
  id: 'churchPastorMember',
  step: 7,
  title: 'Gembala/ Pendeta Senior'
}, {
  id: 'churchActiveMember',
  step: 7,
  title: 'Sekarang Aktif di Gereja'
},{
  id: 'fullBibleReadAmount',
  step: 8,
  title: 'Sudah berapa kali Anda membaca Alkitab secara utuh dari awalsampai akhir?'
}, {
  id: 'spiritualFriendName',
  step: 8,
  title: 'Jika Ada, sebutkan. (isi dengan - bila Tidak)'
}, {
  id: 'personalBadHabit',
  step: 9,
  title: 'Sebutkan karakter atau kebiasaan buruk yang masih menjadi pergumulan Anda.'
}, {
  id: 'personalOvercomeEffort',
  step: 9,
  title: 'Bagaimana usaha Anda untuk menyelesaikan atau mengatasinya?'
}, {
  id: 'heardFromWhere',
  step: 10,
  title: 'Darimana Anda mengetahui tentang STT Amanat Agung?'
// }, {
//   id: 'agreementPlace',
//   step: 11,
//   title: 'Tempat Menyatakan Persetujuan'
}, {
  id: 'agreementDate',
  step: 11,
  title: 'Tanggal Menyatakan Persetujuan'
}, {
  id: 'agreementPict',
  step: 11,
  title: 'Tanda Tangan Anda (file PNG)'
}, {
  id: 'parentAgreementName',
  step: 11,
  title: 'Nama Orang Tua/ Wali'
}, {
  id: 'parentAgreementPict',
  step: 11,
  title: 'Tanda Tangan Orang Tua/ Wali (file PNG)'
}]

export const formValidateRecommendation = [{
  id: 'question1',
  step: 1,
  title: 'Nama Calon Mahasiswa',
}, {
  id: 'question2',
  step: 1,
  title: 'Berapa lama, seberapa baik dan dalam kapasitas sebagai apakah Anda mengenal calon mahasiswa?',
}, {
  id: 'question3',
  step: 1,
  title: 'Apakah calon mahasiswa secara nyata hidup dalam pertobatannya sebagai seorang Kristen?',
}, {
  id: 'question4',
  step: 1,
  title: 'Berikan komentar mengenai panggilan calon mahasiswa sebagai Hamba Tuhan!',
}, {
  id: 'question5',
  step: 1,
  title: 'Seberapa besar motivasi calon mahasiswa untuk menjalani pendidikan teologi?',
}, {
  id: 'question6',
  step: 1,
  title: 'Apakah sebagai anggota gereja, calon mahasiswa selama ini ikut terlibat dalam pelayanan?',
}, {
  id: 'question3',
  step: 1,
  title: 'Apakah calon mahasiswa menunjukkan kedewasaan spiritualitas di dalam kehidupan sebagai rohaniwan?',
}, {
  id: 'question4',
  step: 1,
  title: 'Bagaimana pendapat Anda tentang semangat/kesungguhannya dalam pelayanan sebagai rohaniwan?',
}, {
  id: 'question5',
  step: 1,
  title: 'Beberapa besar motivasi calon mahasiswa untuk menjalani pendidikan teologi?',
}, {
  id: 'question6',
  step: 1,
  title: 'Apakah sebagai calon mahasiswa menunjukkan kesetiaan/kegigihan dalam pelayanan sebagai rohaniwan?',
}, {
  id: 'question4',
  step: 1,
  title: 'Apakah sebagai anggota gereja, calon mahasiswa selama ini terlibat dalam pelayanan?',
}, {
  id: 'question3',
  step: 1,
  title: 'Apakah hal positif dari calon mahasiswa yang dapat mendukung dalam menjalani studi Magister?',
}, {
  id: 'question4',
  step: 1,
  title: 'Adakah kekurangan yang perlu diperhatikan dari calon mahasiswa?',
}, {
  id: 'b_question_1',
  step: 2,
  title: 'Jelaskan bentuk dan intensitas kecenderungan yang Anda garis bawahi di atas',
}, {
  id: 'c_question_1',
  step: 2,
  title: 'Apakah calon mahasiswa pernah mengalami suatu penyakit atau ketegangan mental? Jika ya, Bagaimana ia mengatasinya?',
}, {
  id: 'recommendation_reason',
  step: 3,
  title: 'Alasan:',
}, {
  id: 'filler_name',
  step: 3,
  title: 'Nama',
}, {
  id: 'filler_position',
  step: 3,
  title: 'Posisi/ Jabatan',
}, {
  id: 'filler_church',
  step: 3,
  title: 'Gereja/ Organisasi',
}, {
  id: 'filler_address',
  step: 3,
  title: 'Alamat',
}, {
  id: 'filler_phone',
  step: 3,
  title: 'Telepon',
}, {
  id: 'filler_email',
  step: 3,
  title: 'E-mail',
// }, {
//   id: 'sign_city',
//   step: 4,
//   title: 'Tempat Menyatakan Persetujuan',
}, {
  id: 'sign_date',
  step: 4,
  title: 'Tanggal Menyatakan Persetujuan',
}, {
  id: 'sign_pict_url',
  step: 4,
  title: 'Tanda Tangan Perekomendasi (file PNG)',
}]

export const formValidateCouple = [{
  id: 'nameCoupleAgreement',
  step: 1,
  title: 'Nama',
}, {
  id: 'addressCoupleAgreement',
  step: 1,
  title: 'Alamat',
}, {
  id: 'cityCoupleAgreement',
  step: 1,
  title: 'Kota/ Provinsi',
}, {
  id: 'postalCoupleAgreement',
  step: 1,
  title: 'Kode Pos',
}, {
  id: 'phoneCoupleAgreement',
  step: 1,
  title: 'Telepon/ Hp',
}, {
  id: 'emailCoupleAgreement',
  step: 1,
  title: 'E-mail',
}, {
  id: 'relationCoupleAgreement',
  step: 1,
  title: 'Hubungan dengan Calon Mahasiswa',
}, {
  id: 'candidateNameCoupleAgreement',
  step: 1,
  title: 'Calon Mahasiswa Bernama',
// }, {
//   id: 'signPlaceCoupleAgreement',
//   step: 1,
//   title: 'Tempat Menyatakan Persetujuan',
}, {
  id: 'signDateCoupleAgreement',
  step: 1,
  title: 'Tanggal Menyatakan Persetujuan',
}, {
  id: 'signPictCoupleAgreement',
  step: 1,
  title: 'Tanda Tangan Pasangan yang Menyatakan Persetujuan (file PNG)',
}]